import React from 'react';
import { ViewportProvider } from '@vlinder-web/viewport-provider-react';
import { AppNavigator } from '../AppNavigator';

import { ThemeProvider } from '../providers/theme';
import { Accessories } from './Accessories';
import Watermark from '@uiw/react-watermark';
import { DEFAULT_APP_CONFIG } from '../config/default.config';

export const BootstrapApp = () => {
  return (
    <>
      <ThemeProvider>
        <ViewportProvider>
          <Watermark
            content={DEFAULT_APP_CONFIG?.BRAND_NAME}
            rotate={-20}
            gapX={4}
            gapY={90}
            height={5}
            fontSize={11}
            fontColor='rgb(255 0 0 / 25%)'
          >
            <AppNavigator />
          </Watermark>
        </ViewportProvider>
      </ThemeProvider>
      <Accessories />
    </>
  );
};
